import React, { useState, useEffect } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import { useAppState } from '../../state';
import { useLocation } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Box, Typography } from '@material-ui/core';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

export default function PreJoinScreens() {
  const { user } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  // const { URLRoomName } = useParams<{ URLRoomName?: string }>();
  let query = useQuery();

  const [step, setStep] = useState(Steps.roomNameStep);

  const [name, setName] = useState<string>(user?.displayName || '');
  // const [roomName, setRoomName] = useState<string>('');
  const [bookingId, setBookingId] = useState<string>('');

  const [mediaError, setMediaError] = useState<Error>();

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  useEffect(() => {
    const bookingIdParam = query.get('booking_id');
    const identity = query.get('identity');
    if (bookingIdParam) {
      setBookingId(bookingIdParam);
    }
    if (identity) {
      setName(identity);
    }
    if (bookingId && name) {
      setStep(Steps.deviceSelectionStep);
    }
    // if (URLRoomName) {
    //   setRoomName(URLRoomName);
    //   if (user?.displayName) {
    //     setStep(Steps.deviceSelectionStep);
    //   }
    // }
  }, [user, bookingId, name, query]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  // const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
  //   // @ts-ignore
  //   // if (!window.location.origin.includes('twil.io') && !window.STORYBOOK_ENV) {
  //   //   window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}${window.location.search || ''}`));
  //   // }
  //   setStep(Steps.deviceSelectionStep);
  // };

  return (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />
      {step === Steps.roomNameStep && (
        <Box>
          <Typography variant="h3">Wrong link</Typography>
          <Typography variant="h5">Try joining the session again</Typography>
        </Box>

        // <RoomNameScreen name="" roomName="" setName={val => {}} setRoomName={val => {}} handleSubmit={event => {}} />
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen name={name} bookingId={bookingId} setStep={setStep} />
      )}
    </IntroContainer>
  );
}
